:root {
  /* Brand Colors below */
  --orange: #FF8300;
  --yellow: #FFD000;
  --emerald: #00BAB5;
  --blue: #00C0E8;
  --lightblue: #D4E9FF;
  --darkblue: #00739F;
  --deepblue: #003966;
  --gray: #E6E6E6;
  --lightgray: #D4E9FF;
  --darkgray: #555555;
  --grayOpaque: rgba(230, 230, 230, 0.40);

  /* Padding styles below */
  --paddingSide: 20em;
  --textPadding: 10em;
  --navPadding: 20px;


  --ioMargin: 60vh 0px;

  /* Overall */
  --textColor: #313131;
}

/* html, body {
  overflow-x: hidden;
}

 Above must be moved into specific containers where overflow HAS to be hidden. Otherwise will conflict with sticky positioning */

@font-face {
  font-family: 'Replica';
  src: url('./resources/typefaces/replica/ReplicaPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Replica-Bold';
  src: url('./resources/typefaces/replica/ReplicaPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./resources/typefaces/helvetica-neue/HelveticaNeueLTPro-Roman.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Georgia';
  src: url('./resources/typefaces/georgia/Georgia\ Regular\ font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Georgia-Bold-Italic';
  src: url('./resources/typefaces/georgia/Georgia\ Bold\ Italic\ font.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Georgia-Italic';
  src: url('./resources/typefaces/georgia/georgia\ italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

p, b {
  margin: 0px;
}

p {
  font-family: "Helvetica", sans-serif;
  font-size: 1em;
  line-height: 140.5%;
}

a {
  color: black;
}

.annotations-g > text {
  font-family: 'Georgia-Italic', serif;
}

.chart-title {
  font-family: 'Georgia-Italic', serif;
  font-size: 1em;
}

.g-bold-i {
  font-family: 'Georgia-Bold-Italic', serif;
}

@media screen and (min-width: 1600px) {
  p {
    font-size: 1.3em;
  }
}

@media (min-width: 320px) {
  :root {
    --navPadding: 2rem;
    --textPadding: 2rem;
    --paddingSide: 0.5em;
  }
}

.cursor {
  cursor: pointer;
}

@media (max-height: 500px) {
  .vizContain {
    margin: 9em;
  }
}

@media (max-height: 425px) {
  .vizContain {
    margin: 11em;
  }
}