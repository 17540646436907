.circle {
    position: absolute;
}

.smallCircle {
    width: 20vw;
}

.bigCircle {
    width: 40vw;
}

#topCircle {
    position: fixed;
    top: -6vw;
    left: 45vw;
}

#leftCircle {
    position: fixed;
    top: 85dvh;
    left: -12vw;
}

#rightCircle {
    position: fixed;
    top: 50dvh;
    right: -6vw;
}

.mapContainer {
    position: absolute;
    width: calc(50vw + 50vh);
    left: calc(-8vw - 8vh);
    bottom: calc(20vh - 20vw);

    @media all and (orientation:portrait) {
        bottom: calc(20vh - 20vw);
    }
    @media all and (orientation:landscape) {
        bottom: calc(20vh - 20vw);
    }

    @media screen and (max-width: 500px) {
        bottom: -7vh;
    }

}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 1em 0px;
    position: relative;
    z-index: 2;
}

@media screen and (min-width: 768px) {
    #leftCircle {
        top: 70dvh;
        left: -12vw;
    }
    .smallCircle {
        width: 12vw;
    }
    .bigCircle {
        width: 21vw;
    }
    #topCircle {
        left: 25vw;
    }

    #leftCircle {
        left: -12vw;
    }

    #rightCircle {
        top: 30vw;
    }

    .buttons{
        background: red!important;
    }
}

@media screen and (min-width: 1024px) {
    #leftCircle {
        top: 50dvh;
        left: -12vw;
    }
}

@media screen and (min-width: 1600px) {
    #leftCircle {
        top: 55dvh;
    }
}

@media (min-width: 768px) and (min-height: 768px) and (orientation: portrait) {
    #rightCircle {
        top: 30dvh;
        left: -5vw;
        right: unset;
    }
    #leftCircle {
        top: 60dvh;
        right: -12vw;
        left: unset;
    }
}

@media (max-height: 960px) and (orientation: landscape) {
    .contentContainer {
        margin: 1em 0px;
    }
}

@media (min-width: 768px) and (min-height: 1100px) and (orientation: portrait) {
    .contentContainer {
        margin: 9.5em 0px;
    }
}

@media (min-width: 768px) and (min-height: 1300px) and (orientation: portrait) {
    .contentContainer {
        margin: 12em 0px;
    }
}

@media (min-width: 1280px) and (min-height: 960px) and (orientation: landscape) {
    .contentContainer {
        margin: 12em 0px;
    }
}