.footerChild {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.googleLogoC {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


.googleLogo {
    width: 110px;
}

.georgia {
    font-family: 'Georgia-Italic';
    font-size: 0.95em;
    margin-right: 5px;
}

.logo {
    width: 225px;
    margin-bottom: 10px;
}

.content {
    margin-top: 20px;
}

.hover {
    font-size: 0.95em;
    margin: 0.55em 0px;
    cursor: pointer;
    position: relative;
    width: fit-content;
    transition: 0.6s ease-out;
}

.participants {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.participants > div {
    width: 40%;
}

.hover > p {
    position: relative;
    z-index: 2;
}

.hover:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: yellow;
    transform: translateY(1px) scaleY(0);
    transform-origin: bottom;
    transition: 0.4s ease-out;
    border-radius: 2px;
    z-index: 0;
}

.hover:hover {
    color: #000;
}

.hover:hover:before {
    transform: translateY(-1px) scaleY(1);
}

@media (max-width: 767px) {
    .participants {
        flex-direction: column;
    }
}