.grow {
    flex-grow: 1;
}

.flexed-column {
    display: flex;
    flex-direction: column;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.align-end {
    align-items: flex-end;
}

.align-center {
    align-items: center;
}

.text-center {
    text-align: center;
}

.collab_mob {
    display: none;
}

@media screen and (max-width: 767px) {
    .collab {
        margin-top: 2em;
        display: none;
    }
    .collab_mob {
        display: block;
        margin-bottom: 1em;
    }
}